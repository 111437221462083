/* PAGE STYLES
   ----------------------------- */

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#page-content {
  width: 100%;
  position: relative;
  margin-left: $sidebarWidth;

  &.has-alert {
    margin-top: 48px;
  }
}
#primary-content {
  padding: $pageContentPadding;
}
.page-container {
  width: 100%;
  margin: auto;
  max-width: 1170px;
}
.container {
  width: 100%;
  max-width: 1000px;
  padding-left: 0;
  padding-right: 0;
}
.app-body {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
}
.content-wrap {
  background-color: #ffffff;
  padding: 32px;
  border-radius: 3px;
  @include cardShadow;
}
.full-bleed-content {
  margin: -$pageContentPadding;
}
.narrow-page-container {
  max-width: 1000px;
  margin: auto;
}
.page-wrap {
  margin: auto !important;
}
.rqfield {
  color: $formControlColorRequired;
}
.btn-wrap {
  margin: 2.2em;
}
.text-end {
  float: inline-end;
}
.backBtn {
  background-color: $formControlColorBtn;
  float: right;
}
.backBtn:hover {
  background-color: $formControlColorBtn;
  float: right;
}
.action-btns {
  background-color: $formControlColorBtn;
}
.cancel-btn {
  background-color: $formControlCancelColorBtn;
}
.action-btns,
.cancel-btn {
  font-size: small;
  margin: 0 3px;
  color: $formControlWhiteColorBtn;
}
.autohide {
  -webkit-animation: fadeinout 5s linear forwards;
  animation: fadeinout 5s linear forwards;
}
.table {
  font-size: 13px;
}
.overflow-wrap-hack {
  max-width: 1px;
  word-wrap: break-word;
}
.btn-close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 $formControlWhiteColorBtn;
  opacity: 0.5;
  content: "";
}
.btn-close::after {
  content: "×";
}
.text-wrapped {
  width: "20px";
}
.text-warpped-center {
  width: "10px";
}
.modal-btn-wrap {
  float: right;
}
.logo-wrapper {
  justify-content: center;
  text-align: center;
  margin-bottom: 1em;
}

.nav-img {
  margin: 5px 0;
  width: 75%;
  height: 50%;
}
.text-warpped-center {
  text-align: center;
  width: 5%;
}
.text-warpped {
  width: 15%;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.cards {
  @include cursorPointer;
}
// Responsive

//collapsed side menu
@media screen and (min-width: $breakPointSm) {
  .side-menu-collapsed {
    #page-content {
      margin-left: 50px;
    }
  }
}
@media screen and (max-width: $breakPointSm) {
  #page-content {
    margin-left: 0px;
    margin-top: 50px;
  }
}
@media screen and (max-width: $breakPointXs) {
  #page-content {
    margin-top: 50px;
  }
}
