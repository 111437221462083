/* LOGIN STYLES
   ----------------------------- */

.login-page-wrapper {
    position: relative;
    min-height: 100%;
    z-index: 1;
}
.login-wrap {
	width: auto;
    max-width: 400px;
    display: block;
    margin-top: 30px;
    margin: auto;
}

